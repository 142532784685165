<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <div class="common-page--menu">
        <div class="link link-white" @click="$router.push({ name: 'Index' })">
          {{$t("首頁")}}&nbsp;>&nbsp;
        </div>
        <div class="link link-white" @click="$router.replace({ name: 'MiningLp', query: { type: '1' } })" v-if="type == 1">
          {{ $t("LP挖礦") }}&nbsp;>&nbsp;
        </div>
        <div class="link link-white" @click="$router.replace({ name: 'MiningLp', query: { type: '2' } })" v-else>
          {{ $t("單幣挖礦") }}&nbsp;>&nbsp;
        </div>
        <div class="link">{{$t("質押")}}</div>
      </div>
      <div class="top-header flex-middle">
        <div class="header-between flex-item"></div>
        <div class="header-center">
          {{ $t("質押") }} {{ type === "1" ? `${name} LP` : name }}
        </div>
        <wallet-contract></wallet-contract>
      </div>
    </div>
    <!-- LP -->
    <el-form class="from-box">
      <div class="input-suffix flex-between">
        <span class="input-label"
          >{{ $t("已質押type數量：", {type: type === "1" ? "LP" : name}) }}</span
        >
        <span class="input-value">
          <span class="primary-color">
            {{
              LPInfo.balances && LPInfo.tokenDecimals
                ? new BigNumber(LPInfo.balances)
                    .dividedBy(Math.pow(10, LPInfo.tokenDecimals))
                    .toFixed(6, 1)
                : "--"
            }}
          </span>
          <span class="ml">{{ type === "1" ? "LP" : name }}</span>
        </span>
      </div>
      <div class="input-suffix flex-between">
        <span class="input-label"
          >{{ $t("可質押type數量：", {type: type === "1" ? "LP" : name}) }}</span
        >
        <span class="input-value">
          <span class="primary-color">
            {{ LPInfo.tokenBalance ? LPInfo.tokenBalance : "--" }}
          </span>
          <span class="ml">{{ type === "1" ? "LP" : name }}</span>
        </span>
      </div>
      <div class="input-suffix flex-between">
        <span class="input-label"
          >{{ $t("質押type數量：", {type: type === "1" ? "LP" : name}) }}</span
        >
        <el-input
          :placeholder="$t('請輸入質押數量')"
          class="flex"
          v-model="amount"
        >
          <template #suffix>
            <span class="suffix-span">{{ type === "1" ? "LP" : name }}</span>
          </template>
          <template #append>
            <span class="primary-color" @click="amount = LPInfo.tokenBalance">{{
              $t("全部")
            }}</span>
          </template>
        </el-input>
      </div>

      <div class="form-btn">
        <el-button
          v-if="!address"
          type="primary"
          block
          round
          @click="initWallet"
          >{{ $t("鏈接錢包") }}</el-button
        >
        <el-button
          v-else-if="
            BigNumber(amount).gt(0) &&
              (!LPInfo.tokenAllowance ||
                BigNumber(LPInfo.tokenAllowance).lte(0) ||
                BigNumber(LPInfo.tokenAllowance).lt(
                  BigNumber(amount)
                    .multipliedBy(Math.pow(10, LPInfo.tokenDecimals))
                    .toFixed()
                ))
          "
          type="primary"
          block
          round
          :disabled="loading"
          :loading="loading"
          @click="approve"
          >{{ $t("授權") }}</el-button
        >
        <el-button
          v-else
          type="primary"
          block
          round
          :disabled="loading"
          :loading="loading"
          @click="toStake"
          >{{ $t("質押") }}</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { reactive, toRefs, computed, watch } from "vue";
import WalletContract from "@/components/WalletContract";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import LPStake from "@/utils/LPStake";
import { config } from "@/utils/config";
import BigNumber from "bignumber.js";
import tokenAbi from "@/utils/abi";
import web3Data from "@/utils/initWeb3";
import { ElMessage } from "element-plus";
import { MaxUint256 } from "@ethersproject/constants";
import { useI18n } from "vue-i18n";
export default {
  components: {
    WalletContract
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();
    const data = reactive({
      BigNumber: BigNumber,
      type: route.params.type,
      name: route.params.name,
      tokenAddress: route.params.token,
      web3: computed(() => store.state.provider),
      address: computed(() => store.state.accounts[0]),
      LPStakeContract: null,
      tokenContract: null,
      LPInfo: {},
      amount: "",
      loading: false,
      initWallet: () => {
        if (!data.address) {
          web3Data.initWeb3();
        }
      },
      initContract: () => {
        data.LPStakeContract = new data.web3.eth.Contract(
          LPStake,
          config.LPStake
        );
        data.tokenContract = new data.web3.eth.Contract(
          tokenAbi,
          data.tokenAddress
        );
        data.initData();
      },
      initData: () => {
        data.getInfo();
        data.getBalance();
        data.getAllowance();
      },
      getAllowance: async () => {
        data.LPInfo.tokenAllowance = await data.tokenContract.methods
          .allowance(data.address, config.LPStake)
          .call();
        console.log(data.LPInfo.tokenAllowance);
      },
      approve: () => {
        data.loading = true;
        data.tokenContract.methods
          .approve(config.LPStake, MaxUint256)
          .send({ from: data.address })
          .then(result => {
            data.loading = false;
            data.getAllowance();
          })
          .catch(() => {
            data.loading = false;
          });
      },
      getBalance: async () => {
        let tokenBalance = await data.tokenContract.methods
          .balanceOf(data.address)
          .call();
        let decimals = await data.tokenContract.methods.decimals().call();
        data.LPInfo.tokenDecimals = decimals;
        data.LPInfo.tokenBalanceDecimals = tokenBalance;
        data.LPInfo.tokenBalance = new BigNumber(tokenBalance)
          .dividedBy(Math.pow(10, decimals))
          .toFixed(6, 1);
      },
      getInfo: async () => {
        let result = await data.LPStakeContract.methods
          .userInfo(data.address, data.tokenAddress)
          .call();
        data.LPInfo.balances = result.balances; // 已質押
      },
      toStake: () => {
        if (!data.amount || new BigNumber(data.amount).lte(0)) {
          ElMessage.error(t("請輸入質押數量"));
          return;
        }
        if (new BigNumber(data.amount).gt(data.LPInfo.tokenBalance)) {
          ElMessage.error(t("數量不足"));
          return;
        }
        let amount = new BigNumber(data.amount)
          .multipliedBy(Math.pow(10, data.LPInfo.tokenDecimals))
          .toFixed();
        data.loading = true;
        console.log(data.tokenAddress, amount);
        data.LPStakeContract.methods
          .stake(data.tokenAddress, amount)
          .estimateGas({ from: data.address })
          .then(gas => {
            console.log("gas", gas);
            data.LPStakeContract.methods
              .stake(data.tokenAddress, amount)
              .send({ from: data.address, gas: gas })
              .then(() => {
                data.loading = false;
                data.initData();
              })
              .catch(() => {
                data.loading = false;
              });
          })
          .catch(err => {
            data.loading = false;
            console.log(err);
          });
      }
    });
    if (data.address) {
      data.initContract();
    }
    watch(
      () => data.address,
      val => {
        if (val) {
          data.initContract();
        }
      }
    );
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>
<style lang="scss" scoped>
.address-list {
  max-width: 1400px;
  margin: 30px auto 80px;
}
.bottom-table {
  background: #fff;
  border-radius: 20px;
  margin-top: 50px;
  .table-box {
    max-width: 1400px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 480px) {
  /deep/ .suffix-span {
    padding: 0 6px 0 0 !important;
    font-size: 15px !important;
    border-right: 1px solid #d4d4d4;
    margin-right: -6px;
  }
  /deep/ .from-box .el-input__inner {
    // width: 150px !important;
    font-size: 14px !important;
    padding-right: 35px !important;
    background: #eff2f7;
    border: 0;
  }
  /deep/ .el-input-group__append,
  .el-input-group__prepend {
    padding: 0 7px 0 5px;
  }
  /deep/ .el-input__suffix {
    transform: translate(-39px, -12px) !important;
  }
  /deep/ .from-box .input-suffix .suffix-span {
  }
  /deep/ .from-box .input-suffix .input-label {
    margin-right: 0;
  }
  /deep/ .from-box {
    margin-top: -150px;
  }
  /deep/ .el-input--suffix .el-input__inner {
    padding-right: 45px;
  }
  .from-box {
    padding: 0 15px;
  }
  /deep/ .el-input-group__append,
  .el-input-group__prepend {
    background: #eff2f7;
    border: 0;
  }
  /deep/ .el-input-group {
    background: #eff2f7;
    border-radius: 6px;
  }
}
</style>
